import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/homePage/homePage.vue')
  },
  // 走进natux
  {
    path: '/learnNatux',
    name: 'learnNatux',
    component: () => import('../views/learnNatux/learn_natux.vue')
  },

  // 产品中心
  {
    path: '/product_center',
    name: 'product_center',
    component: () => import('../views/productCenter/product_center.vue')
  },
  
  // 解决方案
  {
    path: '/solution_factory',
    name: 'solution_factory',
    component: () => import('../views/solution/solution_factory.vue')
  },
  {
    path: '/solution_cultivation',
    name: 'solution_cultivation',
    component: () => import('../views/solution/solution_cultivation.vue')
  },
  {
    path: '/solution_labo',
    name: 'solution_labo',
    component: () => import('../views/solution/solution_labo.vue')
  },


  // 客户案例
  {
    path: '/customerCase',
    name: 'customerCase',
    component: () => import('../views/customerCase/customer_case.vue')
  },

  // 联系我们
  {
    path: '/contactUs',
    name: 'contactUs',
    component: () => import('../views/contactUs/contact_us.vue')
  },
]

const router = new VueRouter({
  routes
})

export default router
