<template>
  <div class="bar-container width100 flex items-center">
    <div class="flex-1 height100 flex justify-between items-center bar-inside">
      <img
        v-if="navFlag"
        src="~@/assets/home/top-logo.png"
        alt=""
        class="logo"
      />
      <img
        v-else
        src="~@/assets/commonImages/nav-logo.png"
        alt=""
        class="logo"
      />
      <div class="nav-list flex justify-between">
        <div class="list-item" @click="toHome">
          首页
          <div class="item-bottom"></div>
        </div>
        <div class="list-item" @click="toLearnNatux">
          走进纳图
          <div class="item-bottom"></div>
        </div>

        <el-dropdown placement="bottom-start">
          <div class="list-item flex items-center">
            产品中心
            <div
              v-if="navFlag"
              class="item-after"
              style="border-color: #fff transparent transparent"
            ></div>
            <div v-else class="item-after"></div>
            <div class="item-bottom" style="left: 40%"></div>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="toProduct(0)"
              >环境监控系统</el-dropdown-item
            >
            <el-dropdown-item @click.native="toProduct(1)"
              >能耗监控系统</el-dropdown-item
            >
            <el-dropdown-item @click.native="toProduct(2)"
              >人员管理系统</el-dropdown-item
            >
            <el-dropdown-item @click.native="toProduct(3)"
              >视频监控系统</el-dropdown-item
            >
            <el-dropdown-item @click.native="toProduct(4)"
              >仓储管理系统</el-dropdown-item
            >
            <el-dropdown-item @click.native="toProduct(5)"
              >采样管理系统</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
        <!-- <div class="list-item flex items-center">
          产品中心
          <div class="item-after"></div>
          <div class="item-bottom" style="left: 40%"></div>
        </div> -->

        <el-dropdown placement="bottom-start">
          <div class="list-item flex items-center">
            解决方案
            <div
              v-if="navFlag"
              class="item-after"
              style="border-color: #fff transparent transparent"
            ></div>
            <div v-else class="item-after"></div>
            <div class="item-bottom" style="left: 40%"></div>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="solution(0)"
              >智慧工厂</el-dropdown-item
            >
            <el-dropdown-item @click.native="solution(1)"
              >智慧养殖</el-dropdown-item
            >
            <el-dropdown-item @click.native="solution(2)"
              >智慧实验室</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
        <!-- <div class="list-item flex items-center">
          解决方案
          <div class="item-after"></div>
          <div class="item-bottom"></div>
        </div> -->

        <div class="list-item" @click="toCustomerCase">
          客户案例
          <div class="item-bottom"></div>
        </div>
        <div class="list-item" @click="toContact" style="margin-right: 0">
          联系我们
          <div class="item-bottom item-bottom-small"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      navFlag: true,
    };
  },
  // mounted: function () {
  //   window.addEventListener("scroll", this.handleScroll, true); // 监听（绑定）滚轮滚动事件
  // },
  watch: {
    $route(to) {
      console.log("路由变化了");
      if (this.$route.fullPath == "/") {
         window.addEventListener("scroll", this.handleScroll, true); // 监听（绑定）滚轮滚动事件
        this.navFlag = true;
        document.querySelector(".bar-container").style.background =
          "transparent";
        document.querySelectorAll(".list-item").forEach((item) => {
          item.style.color = "#fff";
        });
      } else {
        window.removeEventListener("scroll" , this.handleScroll , true)
        this.navFlag = false;
        document.querySelector(".bar-container").style.background = "#fff";
        document.querySelectorAll(".list-item").forEach((item) => {
          item.style.color = "#333";
        });
      }
    },
  },
  methods: {
    toHome() {
      this.$route.fullPath == "/" ? "" : this.$router.push("/");
    },

    toLearnNatux() {
      this.$route.fullPath == "/learnNatux"
        ? ""
        : this.$router.push("/learnNatux");
    },

    toCustomerCase() {
      this.$route.fullPath == "/customerCase"
        ? ""
        : this.$router.push("/customerCase");
    },

    toContact() {
      this.$route.fullPath == "/contactUs"
        ? ""
        : this.$router.push("/contactUs");
    },

    solution(p) {
      let targetArr = [
        "/solution_factory",
        "/solution_cultivation",
        "/solution_labo",
      ];
      let targetPath = targetArr[p];
      this.$route.fullPath == targetPath ? "" : this.$router.push(targetPath);
    },

    toProduct(p) {
      let target = "/product_center";
      let targetPath = target + "?product=" + p;

      this.$route.fullPath == targetPath ? "" : this.$router.push(targetPath);
    },

    handleScroll(e) {
      let scrollY = document.documentElement.scrollTop;
      if (scrollY <= 200) {
        this.navFlag = true
        document.querySelector(".bar-container").style.background =
          "transparent";
        document.querySelectorAll(".list-item").forEach((item) => {
          item.style.color = "#fff";
        });
      } else {
        this.navFlag = false;
        document.querySelector(".bar-container").style.background = "#fff";
        document.querySelectorAll(".list-item").forEach((item) => {
          item.style.color = "#333";
        });
      }
    },
  },
};
</script>


<style lang="scss" scoped>
@function vw($px) {
  @return ($px / 1920) * 100vw;
}

.bar-container-transparent {
  background: transparent;
}

.bar-container {
  transition: .5s ease all;
  position: fixed;
  top: 0;
  left: 0;
  height: vw(84);
  background: transparent;
  box-shadow: 0 0 10px 3px rgba($color: #000000, $alpha: 0.1);
  z-index: 3;
  .bar-inside {
    padding: 0 vw(160) 0 vw(220);
    .logo {
      width: vw(127);
      height: vw(41);
    }

    .nav-list {
      .list-item {
        color: #333;
        // color:#fff;
        font-size: vw(16);
        font-weight: bold;
        margin-right: vw(39);
        cursor: pointer;
        transition: 0.5s ease all;
        position: relative;
        .item-after {
          width: 0px;
          height: 0px;
          border: 5px solid;
          border-bottom: none;
          border-color: #333333 transparent transparent;
          margin-left: vw(6);
          transition: 0.5s ease all;
        }

        .item-bottom {
          width: vw(18);
          height: vw(9);
          border-radius: 0 0 500px 500px;
          border: 3px solid rgba(255, 130, 37, 1);
          border-top-color: transparent;
          border-left-color: transparent;
          border-right-color: transparent;
          position: absolute;
          left: 50%;
          bottom: -10px;
          transform: translateX(-50%);
          transition: 0.5s ease all;
          opacity: 0;
        }
        .item-bottom-small {
          left: 50%;
        }
        &:last-child {
          // padding-right: 0;
        }
        &:hover {
          color: rgba(255, 130, 37, 1);
          .item-after {
            border-color: rgba(255, 130, 37, 1) transparent transparent;
            transform: rotateZ(180deg);
          }
          .item-bottom {
            opacity: 1;
          }
        }
      }
    }
  }
}

::v-deep .el-dropdown-menu__item {
  color: #333333;
  text-align: center;
  &:not(.is-disabled):hover {
    background-color: rgba(255, 222, 197, 1);
    color: #333;
  }
}
</style>